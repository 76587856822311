// data.ts

interface Casino {
    logo: string;
    name: string;
    rating: number;
    votes: number;
    bonus: string;
    deposit: string;
    link: string;
}

export const casinosData: Casino[] = [
    {
        logo: "/images/nossaaposta.svg",
        name: "Nossaaposta",
        rating: 9.9,
        votes: 1500,
        bonus: "750€ EM BONUS",
        deposit: "10€",
        link: "https://www.nossaaposta.pt/"
    },
    {
        logo: "/images/bwin.svg",
        name: "BWIN",
        rating: 9.8,
        votes: 1400,
        bonus: "BONUS DE SLOTS DE 50% ATE 50 EUROS",
        deposit: "10€",
        link: "https://sports.bwin.pt/pt/sports"
    },
    {
        logo: "/images/portugal.svg",
        name: "Casino Portugal",
        rating: 9.7,
        votes: 1209,
        bonus: "100% Bonus up to $1000 + $25 Token",
        deposit: "10€",
        link: "https://www.casinoportugal.pt/casino/"
    },
    {
        logo: "/images/888casino.svg",
        name: "888 Casino",
        rating: 9.6,
        votes: 1021,
        bonus: "Bônus de 100% até 1.000€ no seu primeiro depósito",
        deposit: "10€",
        link: "https://casino.888.pt/"
    },
    {
        logo: "/images/bacanplay.svg",
        name: "BacanaPlay",
        rating: 9.5,
        votes: 1250,
        bonus: "Bônus de Boas-Vindas Deposita já e recebe 100% até €250 Código de Bônus: BACANAPT",
        deposit: "10€",
        link: "https://www.bacanaplay.pt/"
    },
    {
        logo: "/images/betway.svg",
        name: "Betway",
        rating: 9.5,
        votes: 1040,
        bonus: "Bônus de jogo e boas-vindas",
        deposit: "10€",
        link: "https://www.betway.pt/notallowed.htm"
    },
    {
        logo: "/images/luckia.svg",
        name: "Luckia",
        rating: 9.5,
        votes: 1020,
        bonus: "Novos Clientes 500€ Bônus",
        deposit: "10€",
        link: "https://www.luckia.pt/"
    },
    {
        logo: "/images/casino.svg",
        name: "Pt Casino",
        rating: 9.5,
        votes: 1010,
        bonus: "SE QUERES DIVERSÃO VIESTE BATER À PORTA CERTA EXPERIMENTA JÁ!",
        deposit: "10€",
        link: "https://www.placard.pt/apostas"
    },
    {
        logo: "/images/pokerstar.svg",
        name: "PokerStars",
        rating: 9.5,
        votes: 1000,
        bonus: "BONUS DE 100% ATE €1.000",
        deposit: "10€",
        link: "https://www.pokerstars.pt/"
    },
    {
        logo: "/images/esc.svg",
        name: "ESC Online",
        rating: 9.5,
        votes: 1000,
        bonus: "Jogue nas slots selecionadas da Pragmatic e ganhe uma parte do Jackpot de 3.000€!",
        deposit: "10€",
        link: "https://www.estorilsolcasinos.pt/"
    },
    {
        logo: "/images/Solverde.svg",
        name: "Solverde",
        rating: 9.5,
        votes: 1000,
        bonus: "Aproveita um bónus de depósito em casino de 100% até 100€ e ganha 30€ em Freebets",
        deposit: "10€",
        link: "https://www.solverde.pt/"
    }
];
