import React from 'react';

const TermsOfService: React.FC = () => {
    return (
        <div className="container mx-auto px-6 pt-32 text-white rounded-lg shadow-lg  space-y-6">
            <h1 className="text-3xl font-bold text-center text-white mb-6">
                Termos e Condições de Uso
            </h1>

            <p className="text-gray-300 text-lg">
                Estes Termos e Condições governam o uso do nosso site. Ao acessar ou utilizar nosso site, você concorda em estar vinculado a estes termos. Se você não concorda com qualquer parte dos termos, por favor, não utilize o site.
            </p>

            <h2 className="text-2xl font-semibold text-white mt-4">
                Uso do Site
            </h2>
            <p className="text-gray-300">
                O conteúdo e os materiais deste site são fornecidos para fins informativos e de entretenimento. Você concorda em utilizar o site de forma responsável e a não violar qualquer lei aplicável durante o uso.
            </p>

            <h2 className="text-2xl font-semibold text-white mt-4">
                Direitos de Propriedade Intelectual
            </h2>
            <p className="text-gray-300">
                Todo o conteúdo, incluindo textos, gráficos, logotipos e imagens, é propriedade exclusiva deste site ou de seus licenciadores. Você não pode reproduzir, distribuir ou modificar qualquer conteúdo sem nossa autorização prévia.
            </p>

            <h2 className="text-2xl font-semibold text-white mt-4">
                Limitação de Responsabilidade
            </h2>
            <p className="text-gray-300">
                Não garantimos que o site estará sempre disponível ou livre de erros. Em nenhuma circunstância seremos responsáveis por quaisquer danos diretos, indiretos, incidentais ou consequenciais decorrentes do uso ou incapacidade de uso do site.
            </p>

            <h2 className="text-2xl font-semibold text-white mt-4">
                Links para Terceiros
            </h2>
            <p className="text-gray-300">
                Nosso site pode conter links para sites de terceiros. Estes links são fornecidos apenas para conveniência e não endossamos ou garantimos a precisão de qualquer informação fornecida por esses sites. O uso de sites de terceiros é feito por sua conta e risco.
            </p>

            <h2 className="text-2xl font-semibold text-white mt-4">
                Modificações nos Termos
            </h2>
            <p className="text-gray-300">
                Reservamo-nos o direito de modificar estes Termos e Condições a qualquer momento. Quaisquer alterações entrarão em vigor imediatamente após a publicação no site. Recomendamos que você revise esta página regularmente para se manter atualizado sobre nossos termos.
            </p>

            <h2 className="text-2xl font-semibold text-white mt-4">
                Lei Aplicável
            </h2>
            <p className="text-gray-300">
                Estes Termos e Condições são regidos pelas leis do Brasil. Quaisquer disputas relacionadas ao uso do site serão resolvidas nos tribunais competentes do Brasil.
            </p>

            <p className="text-gray-400 text-sm text-center mt-6">
                &copy; {new Date().getFullYear()} Todos os direitos reservados.
            </p>
        </div>
    );
};

export default TermsOfService;
