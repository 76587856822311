import React from 'react';
import CasinoCard from '../CasinoCard/CasinoCard';
import { casinosData } from './casinoList';

const Casinos = () => {
    return (
        <section className="bg-gray-900 py-10">
            <h1 className="text-3xl sm:text-4xl font-bold text-white text-center mb-8 sm:mb-10">TOP RATED CASINOS</h1>
            <div className="container mx-auto px-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                {casinosData.map((casino, index) => (
                    <CasinoCard
                        key={index}
                        logo={casino.logo}
                        name={casino.name}
                        rating={casino.rating}
                        votes={casino.votes}
                        bonus={casino.bonus}
                        deposit={casino.deposit}
                        link={casino.link}
                    />
                ))}
            </div>
        </section>
    );
};

export default Casinos;
