import React, { useState } from 'react';
import { navLinks } from '../navLinks';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <header
            style={{ backdropFilter: 'blur(90px)' }}
            className="fixed bg-black bg-opacity-80 z-50 top-0 w-full text-white px-6 py-4"
        >
            <div className="container mx-auto flex items-center justify-between">
                {/* Logo */}
                <a href="/" className="flex items-center">
                    <img src="/logo.svg" alt="" />
                </a>

                {/* Desktop Menu */}
                <nav className="hidden md:flex space-x-8">
                    {navLinks.map((link: any, index: number) => (
                        <a key={index} href={link.path} className="hover:text-gray-400">
                            {link.name}
                        </a>
                    ))}
                </nav>

                {/* Hamburger Menu for Mobile */}
                <div className="md:hidden">
                    <button onClick={() => setIsOpen(!isOpen)} className="focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </button>
                </div>
            </div>

            {/* Mobile Menu */}
            {isOpen && (
                <nav className="md:hidden mt-4">
                    {navLinks.map((link: any, index: number) => (
                        <a key={index} href={link.path} className="block py-2 hover:text-gray-400">
                            {link.name}
                        </a>
                    ))}
                </nav>
            )}
        </header>
    );
};

export default Header;
