import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Show the cookie banner only if the user has not accepted cookies in the session
        if (!sessionStorage.getItem('cookieAccepted')) {
            setIsVisible(true);
        }

        // Disable scroll if the cookie consent is visible
        if (isVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        // Cleanup on unmount
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isVisible]);

    const handleAccept = () => {
        // Save acceptance to sessionStorage and hide the banner
        sessionStorage.setItem('cookieAccepted', 'true');
        setIsVisible(false);
    };

    const handleLinkClick = (event: any, href: any) => {
        // Prevent default link navigation
        event.preventDefault();
        // Hide the popup
        setIsVisible(false);
        // Delay navigation slightly to allow state change to take effect
        setTimeout(() => {
            window.location.href = href;
        }, 100);
    };

    return (
        <>
            {isVisible && (
                <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md z-50 flex items-center justify-center">
                    <div className="w-[90%] max-w-md p-6 bg-white text-gray-800 rounded-lg shadow-lg border border-gray-200">
                        <h2 className="text-xl font-semibold mb-4 text-center">
                            Nós valorizamos sua privacidade
                        </h2>
                        <p className="text-sm text-center mb-4">
                            Utilizamos cookies para melhorar sua experiência e analisar o uso do site. Ao usar nosso site, você concorda com o uso de cookies. Veja nossa{' '}
                            <a
                                href="/privacy"
                                className="text-blue-500 underline hover:text-blue-700"
                                onClick={(event) => handleLinkClick(event, '/privacy')}
                            >
                                Política de Privacidade
                            </a>{' '},
                            nossos{' '}
                            <a
                                href="/terms"
                                className="text-blue-500 underline hover:text-blue-700"
                                onClick={(event) => handleLinkClick(event, '/terms')}
                            >
                                Termos de Uso
                            </a>{' '}
                            e nossa{' '}
                            <a
                                href="/cookie"
                                className="text-blue-500 underline hover:text-blue-700"
                                onClick={(event) => handleLinkClick(event, '/cookie')}
                            >
                                Política de Cookies
                            </a>{' '}
                            para mais informações.
                        </p>
                        <div className="flex justify-center space-x-3">
                            <button
                                onClick={handleAccept}
                                className="bg-blue-500 text-white py-2 px-6 rounded-lg shadow hover:bg-blue-700 focus:outline-none transition duration-300"
                            >
                                Aceitar
                            </button>
                            <button
                                onClick={() => setIsVisible(false)}
                                className="bg-gray-200 text-gray-700 py-2 px-6 rounded-lg shadow hover:bg-gray-300 focus:outline-none transition duration-300"
                            >
                                Recusar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CookieConsent;
