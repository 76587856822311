import React from 'react';

const ResponsibleGame = () => {
    return (
        <div className=" text-white pt-32 p-6 rounded-lg">
            <h1 className="text-3xl font-bold mb-6">Jogo Responsável</h1>
            <p className="text-lg mb-4">
                <strong>Aproveite ao Máximo Sua Experiência de Jogo com Responsabilidade</strong>
            </p>
            <p className="text-lg mb-4">
                No <strong>Fun Casino</strong>, acreditamos que jogar de forma responsável é essencial para garantir uma experiência agradável e equilibrada. Quando os jogadores respeitam os limites que estabeleceram para si mesmos, o entretenimento se torna mais divertido e saudável. Definir limites claros e apropriados é uma forma eficaz de manter o controle e evitar problemas. Caso perceba que está ultrapassando esses limites, é importante agir imediatamente.
            </p>
            <p className="text-lg mb-4">
                Ao iniciar sua jornada no Fun Casino, você tem a possibilidade de definir limites pessoais adaptados às suas metas de curto e longo prazo. Isso inclui restrições para períodos específicos, como 24 horas, uma semana ou um mês. Se preferir, você também pode pausar completamente suas atividades no cassino por um período determinado.
            </p>
            <p className="text-lg mb-4">
                Nosso objetivo é oferecer um suporte excepcional e garantir que todos os nossos jogadores desfrutem de uma experiência segura e divertida. Abaixo, você encontra um guia completo para aproveitar o Fun Casino da melhor forma possível.
            </p>

            <h2 className="text-2xl font-semibold mb-4">Opções de Limites Disponíveis no Fun Casino</h2>
            <ul className="list-disc pl-6 mb-6">
                <li>Estabelecer um limite para o valor que pode ser depositado.</li>
                <li>Definir restrições para os gastos gerais.</li>
                <li>Configurar limites de apostas em períodos específicos.</li>
                <li>Limitar o tempo que você pode permanecer logado na sua conta.</li>
                <li>
                    Fazer uma pausa no jogo, escolhendo períodos de <strong>sete dias, 30 dias, seis meses ou um bloqueio permanente da conta</strong>.
                </li>
            </ul>

            <h2 className="text-2xl font-semibold mb-4">Controle de Gastos e Restrições</h2>
            <p className="text-lg mb-4">
                Novos clientes do Fun Casino começam com limites padrão, aplicados em períodos diários, semanais e mensais. Esses limites podem ser ajustados a qualquer momento, garantindo que os jogadores mantenham o controle de suas atividades.
            </p>
            <p className="text-lg mb-4">
                No entanto, contas bloqueadas não podem ser desbloqueadas antes do prazo estipulado pelo próprio jogador. Caso deseje desbloquear sua conta antes desse período, será necessário enviar uma carta explicando os motivos do bloqueio e as razões para o desbloqueio antecipado.
            </p>

            <h2 className="text-2xl font-semibold mb-4">Depósitos e Processo de Verificação</h2>
            <p className="text-lg mb-4">
                Para depósitos com cartão de crédito, é obrigatório passar por um processo de segurança que inclui a verificação de documentos. O suporte ao cliente do Fun Casino está disponível 24 horas por dia, facilitando o envio dos documentos necessários via chat ao vivo ou e-mail. Durante essa verificação, nossa equipe pode entrar em contato com você por telefone ou e-mail para confirmar as informações.
            </p>
            <p className="text-lg mb-4">
                Além disso, antes de realizar qualquer saque, a conta do jogador deve ser aprovada no processo de verificação.
            </p>

            <h2 className="text-2xl font-semibold mb-4">Encerramento Permanente da Conta</h2>
            <p className="text-lg mb-4">
                Todos os jogadores têm o direito de encerrar sua conta permanentemente a qualquer momento. Caso decida fechar sua conta no Fun Casino, basta acessar as configurações, clicar em “Encerrar Conta” e depois em “Para Sempre”. Uma vez confirmada, a conta será encerrada de forma definitiva.
            </p>
            <p className="text-lg mb-4">
                <strong>ATENÇÃO:</strong> Após o encerramento permanente, a conta não poderá ser reaberta e qualquer saldo remanescente será perdido.
            </p>

            <h2 className="text-2xl font-semibold mb-4">Atendimento ao Cliente</h2>
            <p className="text-lg mb-4">
                O <strong>Fun Casino</strong> se orgulha de oferecer suporte completo a todos os seus jogadores. Nossa equipe está disponível 24 horas por dia, todos os dias, pronta para ajudar com qualquer dúvida ou problema. Você pode entrar em contato com o suporte ao cliente por chat ao vivo, e-mail ou telefone.
            </p>


        </div>
    );
};

export default ResponsibleGame;
