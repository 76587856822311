import React from 'react';

const CookiePolicy = () => {
    return (
        <div className="p-6 container  pt-32   mx-auto text-white">
            <h1 className="text-3xl font-bold text-center mb-6">Política de Cookies</h1>
            <p className="mb-4">
                Nosso site utiliza cookies para melhorar a sua experiência, personalizar conteúdo, fornecer
                funcionalidades de mídia social e analisar nosso tráfego. Esta Política de Cookies explica
                o que são cookies, como os utilizamos e como você pode controlá-los.
            </p>

            <h2 className="text-2xl font-semibold mb-4">O que são cookies?</h2>
            <p className="mb-4">
                Cookies são pequenos arquivos de texto armazenados no seu dispositivo quando você visita
                um site. Eles ajudam o site a lembrar informações sobre sua visita, como idioma
                preferido e outras configurações, facilitando sua próxima visita.
            </p>

            <h2 className="text-2xl font-semibold mb-4">Por que usamos cookies?</h2>
            <p className="mb-4">
                Usamos cookies para melhorar o desempenho do site e para oferecer uma experiência mais
                personalizada. As finalidades incluem:
            </p>
            <ul className="list-disc list-inside mb-4">
                <li>Memorizar suas preferências, como idioma e configurações regionais;</li>
                <li>Garantir que o site funcione corretamente;</li>
                <li>Coletar dados analíticos para melhorar nosso conteúdo e serviços;</li>
                <li>Fornecer anúncios relevantes e personalizados.</li>
            </ul>

            <h2 className="text-2xl font-semibold mb-4">Tipos de Cookies</h2>
            <p className="mb-4">
                Utilizamos diferentes tipos de cookies para atender às suas necessidades:
            </p>
            <ul className="list-disc list-inside mb-4">
                <li>
                    <strong>Cookies Necessários:</strong> Essenciais para o funcionamento do site.
                </li>
                <li>
                    <strong>Cookies de Desempenho:</strong> Usados para analisar o tráfego e melhorar o
                    desempenho do site.
                </li>
                <li>
                    <strong>Cookies Funcionais:</strong> Permitem ao site lembrar suas escolhas e
                    configurações.
                </li>
                <li>
                    <strong>Cookies de Publicidade:</strong> Usados para personalizar anúncios e medir
                    sua eficácia.
                </li>
            </ul>

            <h2 className="text-2xl font-semibold mb-4">Como gerenciar cookies?</h2>
            <p className="mb-4">
                Você pode controlar e gerenciar cookies através das configurações do seu navegador.
                Isso inclui a exclusão de cookies existentes ou o bloqueio de novos. No entanto, isso pode
                afetar sua experiência no site.
            </p>


            <h2 className="text-2xl font-semibold mb-4">Alterações nesta Política</h2>
            <p className="mb-4">
                Podemos atualizar esta Política de Cookies a qualquer momento para refletir mudanças nas
                nossas práticas ou por outras razões operacionais, legais ou regulatórias. Recomendamos que
                você reveja esta página periodicamente para se manter informado.
            </p>

            <h2 className="text-2xl font-semibold mb-4">Entre em contato</h2>
            <p className="mb-4">
                Se você tiver dúvidas sobre nossa Política de Cookies, entre em contato conosco através
                do nosso{' '}

                formulário de contato

                .
            </p>
        </div>
    );
};

export default CookiePolicy;
