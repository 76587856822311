import React from 'react';
import AppRoutes from './components/routes';

const App: React.FC = () => {
  return (
    <AppRoutes />
  );
};

export default App;
