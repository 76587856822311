// pages/Home.tsx
import React from 'react';
import Hero from '../components/Hero/Hero';
import Casinos from '../components/Casinos/Casinos';
import OnlineCasinosInfo from '../components/OnlineCasinoInfo/OnlineCasinoInfo';
import CookieConsent from '../components/Cookie/Cookie';
import NewAddedText from '../components/NewAddedText';

const Home: React.FC = () => {
    return (
        <div>
            <Hero />
            <Casinos />
            <OnlineCasinosInfo />

            <CookieConsent />
        </div>
    );
};

export default Home;
