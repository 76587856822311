import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface FormData {
    name: string;
    email: string;
    phone: string;
    message: string;
}

const ContactPage: React.FC = () => {
    const [formData, setFormData] = useState<FormData>({
        name: "",
        email: "",
        phone: "",
        message: "",
    });

    const [errors, setErrors] = useState<Partial<FormData>>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = (): boolean => {
        const { name, email, phone, message } = formData;
        const newErrors: Partial<FormData> = {};

        if (!name.trim()) newErrors.name = "Nome é obrigatório";
        if (!email.trim()) newErrors.email = "E-mail é obrigatório";
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email && !emailRegex.test(email)) newErrors.email = "E-mail inválido";
        if (phone && phone.length < 10) newErrors.phone = "Telefone inválido";
        if (!message.trim()) newErrors.message = "Mensagem é obrigatória";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateForm()) return;

        setIsLoading(true);

        // Simüle edilen API isteği
        setTimeout(() => {
            setIsLoading(false);
            toast.success("Mensagem enviada com sucesso!");
            setFormData({
                name: "",
                email: "",
                phone: "",
                message: "",
            });
            setErrors({});
        }, 2000);
    };

    return (
        <div className="flex flex-col justify-center items-center min-h-screen ">
            {/* Bilgi Alanı */}
            <div className="mb-4 text-center">
                <p className="text-white">
                    Para dúvidas ou suporte, entre em contato pelo e-mail:{" "}
                    <a href="mailto:info@example.com" className="text-blue-500 hover:underline">
                        info@topcasinoreviews.com
                    </a>
                </p>
            </div>

            {/* Form */}
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
                <h1 className="text-3xl font-bold text-center mb-6 text-blue-600">Entre em Contato</h1>
                <form onSubmit={handleSubmit} className="space-y-6">
                    {/* Nome */}
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                            Nome
                        </label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${errors.name ? "border-red-500" : ""
                                }`}
                            placeholder="Seu Nome"
                        />
                        {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
                    </div>

                    {/* E-mail */}
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            E-mail
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${errors.email ? "border-red-500" : ""
                                }`}
                            placeholder="seuemail@exemplo.com"
                        />
                        {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                    </div>

                    {/* Telefone */}
                    <div>
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                            Telefone
                        </label>
                        <input
                            type="text"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${errors.phone ? "border-red-500" : ""
                                }`}
                            placeholder="Seu Telefone (opcional)"
                        />
                        {errors.phone && <p className="text-red-500 text-xs mt-1">{errors.phone}</p>}
                    </div>

                    {/* Mensagem */}
                    <div>
                        <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                            Mensagem
                        </label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${errors.message ? "border-red-500" : ""
                                }`}
                            placeholder="Sua mensagem aqui"
                        ></textarea>
                        {errors.message && <p className="text-red-500 text-xs mt-1">{errors.message}</p>}
                    </div>

                    {/* Submit Butonu */}
                    <button
                        type="submit"
                        disabled={isLoading}
                        className={`w-full flex justify-center items-center bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 ${isLoading ? "opacity-50" : ""
                            }`}
                    >
                        {isLoading ? (
                            <span className="loader border-2 border-t-2 border-white border-opacity-50 rounded-full w-5 h-5 animate-spin"></span>
                        ) : (
                            "Enviar"
                        )}
                    </button>
                </form>
                <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
            </div>
        </div>
    );
};

export default ContactPage;
