import React from 'react';

const Hero: React.FC = () => {
    return (
        <section
            style={{
                backgroundImage: 'url(/images/bg.png)',
                backgroundAttachment: 'fixed',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}
            className="bg-black text-white py-16 w-full sm:py-24 md:py-32 flex flex-col items-center"
        >
            <div className="container mx-auto px-4">
                {/* Başlık ve Metin */}
                <div className="text-start rounded-lg bg-opacity-70">
                    <div className="max-w-3xl">
                        <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4">
                            Os 10 principais cassinos online em Portugal - 4 de novembro de 2024
                        </h1>
                        <p className="text-md sm:text-lg md:text-xl text-white mb-8">
                            Está à procura dos melhores sites de cassino? Nossa lista inclui alguns dos sites de jogos de azar mais populares em Portugal, facilitando a comparação entre eles. Leia avaliações e explore os bônus de boas-vindas. Com as nossas informações, você poderá rapidamente encontrar os melhores cassinos online em Portugal de forma simples e segura.
                        </p>
                        <div className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-8 mt-8 text-center">
                            <div className="flex flex-col items-center">
                                <svg className="w-8 h-8 sm:w-10 sm:h-10 text-green-500 mb-2" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M12 2l8.5 10h-17z" />
                                </svg>
                                <span className="text-green-500 font-semibold text-sm sm:text-base">Seguro e confiável</span>
                            </div>
                            <div className="flex flex-col items-center">
                                <svg className="w-8 h-8 sm:w-10 sm:h-10 text-green-500 mb-2" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M12 2l8.5 10h-17z" />
                                </svg>
                                <span className="text-green-500 font-semibold text-sm sm:text-base">Retiradas Rápidas</span>
                            </div>
                            <div className="flex flex-col items-center">
                                <svg className="w-8 h-8 sm:w-10 sm:h-10 text-green-500 mb-2" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M12 2l8.5 10h-17z" />
                                </svg>
                                <span className="text-green-500 font-semibold text-sm sm:text-base">Suporte 24/7</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;
