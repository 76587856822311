import React from 'react';

interface CasinoCardProps {
    logo: string;
    name: string;
    rating: number;
    votes: number;
    bonus: string;
    deposit: string;
    link: string;
}

const CasinoCard: React.FC<CasinoCardProps> = ({ logo, name, rating, votes, bonus, deposit, link }) => {
    return (
        <div className="bg-[#2a2a2a] mb-3 text-white p-6 rounded-xl transition-shadow border border-gray-700 w-full flex flex-col justify-between mx-auto">
            {/* Logo and Name Section */}
            <div className="flex flex-col items-center mb-4">
                <img src={logo} alt={name} className="w-full h-44 object-cover rounded-md mb-4" />
                <h3 className="text-2xl font-semibold text-white text-center">{name}</h3>
                <span className="bg-yellow-400 text-black text-xs font-bold px-3 py-1 rounded-full uppercase mt-2">Top Casino</span>
            </div>

            {/* Bonus Section */}
            <div className="bg-gray-800 p-4 rounded-lg mb-4 text-center">
                <p className="text-sm font-medium text-gray-400">{bonus}</p>
                <p className="text-2xl font-bold text-green-400">Depósito Mínimo: {deposit}</p>
            </div>

            {/* Rating and Votes */}
            <div className="flex items-center justify-between mb-4 text-center">
                <div className="flex items-center space-x-1">
                    <span className="text-2xl font-bold text-yellow-500">{rating}/10</span>
                    <span className="text-sm text-gray-400">Our Score</span>
                </div>
                <div className="flex items-center text-sm text-gray-400">
                    <span className="mr-1">⭐</span>
                    <span>{votes} votes</span>
                </div>
            </div>

            {/* Payment Methods */}
            <div className="flex justify-center space-x-4 text-gray-400 mb-4">
                <span className="text-lg">💳</span>
                <span className="text-lg">🍎</span>
                <span className="text-lg">💵</span>
                <span className="text-lg">💳</span>
            </div>

            {/* Get Bonus Button */}
            <a href={link} target="_blank" rel="noopener noreferrer">
                <button className="w-full py-3 bg-yellow-500 text-black font-semibold rounded-lg hover:bg-yellow-600 transition-colors">
                    Get Bonus
                </button>
            </a>
        </div>
    );
};

export default CasinoCard;
