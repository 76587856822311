import React from 'react';

const PrivacyPolicy: React.FC = () => {
    return (
        <div className="container mx-auto pt-32 px-6  text-white rounded-lg shadow-lg  space-y-6">
            <h1 className="text-3xl font-bold text-center text-white mb-6">
                Política de Privacidade
            </h1>

            <p className="text-gray-300 text-lg">
                Sua privacidade é importante para nós. Esta política explica como coletamos, usamos e protegemos suas informações pessoais quando você acessa nosso site.
            </p>

            <h2 className="text-2xl font-semibold text-white mt-4">
                Informações que Coletamos
            </h2>
            <p className="text-gray-300">
                Coletamos diversas informações para proporcionar uma experiência personalizada e eficiente em nosso site. Isso inclui:
            </p>
            <ul className="list-disc list-inside text-gray-300 space-y-2">
                <li>Informações fornecidas diretamente por você, como nome, endereço de e-mail e telefone.</li>
                <li>Informações sobre seu dispositivo, como endereço IP, tipo de navegador e sistema operacional.</li>
                <li>Dados de navegação, como as páginas visitadas, tempo de permanência e interações no site.</li>
            </ul>

            <h2 className="text-2xl font-semibold text-white mt-4">
                Como Usamos Suas Informações
            </h2>
            <p className="text-gray-300">
                Utilizamos suas informações para:
            </p>
            <ul className="list-disc list-inside text-gray-300 space-y-2">
                <li>Personalizar sua experiência no site.</li>
                <li>Melhorar nossos serviços e funcionalidades.</li>
                <li>Enviar comunicações, promoções e atualizações sobre nosso conteúdo.</li>
            </ul>

            <h2 className="text-2xl font-semibold text-white mt-4">
                Compartilhamento de Informações
            </h2>
            <p className="text-gray-300">
                Nós não vendemos, trocamos ou alugamos suas informações pessoais para terceiros. Podemos compartilhar informações agregadas e não identificáveis com parceiros para fins analíticos.
            </p>

            <h2 className="text-2xl font-semibold text-white mt-4">
                Segurança das Informações
            </h2>
            <p className="text-gray-300">
                Implementamos medidas de segurança para proteger suas informações contra acesso não autorizado, alteração, divulgação ou destruição. No entanto, nenhum sistema é completamente seguro, e não podemos garantir a segurança absoluta de suas informações.
            </p>

            <h2 className="text-2xl font-semibold text-white mt-4">
                Seus Direitos
            </h2>
            <p className="text-gray-300">
                Você tem o direito de acessar, corrigir ou excluir suas informações pessoais, bem como de solicitar a interrupção do uso de suas informações para determinados fins. Entre em contato conosco para exercer esses direitos.
            </p>

            <h2 className="text-2xl font-semibold text-white mt-4">
                Alterações na Política de Privacidade
            </h2>
            <p className="text-gray-300">
                Esta política pode ser atualizada periodicamente para refletir mudanças em nossas práticas. Recomendamos que você revise esta página regularmente para se manter informado sobre nossas práticas de privacidade.
            </p>

            <p className="text-gray-400 text-sm text-center mt-6">
                &copy; {new Date().getFullYear()} Todos os direitos reservados.
            </p>
        </div>
    );
};

export default PrivacyPolicy;
